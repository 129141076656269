@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

button.chakra-button {
  border-radius: 0;
  font-size: 13px;
  padding: 10px 15px;
  height: auto;
}

span.chakra-button__icon {
  margin-right: 3px;
  font-size: 15px;
}

.table-row-hover:hover {
  background-color: #eaeaea;
}

.phone-input {
  width: 100% !important;
  border: 1px solid;
  border-radius: 5px;
  padding: 20px;
}

.option-select button {
  background-color: #f6f1ee;
  border: 1px solid #efe1d9;
  color: #7f7472;
  min-width: 45px;
  min-height: 35px;
  border-radius: 0;
  margin-right: 5px;
  padding: 10px;
}

.option-select button:hover {
  background-color: #f6f1ee;
  border: 1px solid #866559;
}

.option-select button.selected {
  background-color: #c17f67;
  border: 1px solid #866559;
  color: white;
}

.option-select button.selected img {
  filter: invert(1);
}

img.diamond-shape-img {
  max-width: 28px;
}

.chakra-ui-dark img.diamond-shape-img {
  filter: invert(1);
}

.option-select button img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.option-select button p {
  font-weight: bold;
}

.option-select button.selected p {
  color: white;
}

.react-tel-input .flag-dropdown {
  border: 0 !important;
}

.react-tel-input .selected-flag {
  padding: 0px 0px 0px 5px !important;
}

.diamond-detail-container table {
  width: 100%;
  border-collapse: collapse;
}

.diamond-detail-container th, .diamond-detail-container td {
  width: 20%;
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: normal; 
  padding: 2px 5px !important;
  font-size: 13px;
}

.diamond-detail-container th {
  padding-bottom: 10px;
  font-size: 14px;
}

.supplier-detail-table th, .supplier-detail-table td {
  width: 20%;
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: normal; 
  padding: 3px 5px;
  font-size: 14px;
}

.link-btn {
  color: #0000EE !important;
  font-weight: 500 !important;
}

a.sidebar-menu-link:hover {
  background-color: #efe1d9;
  display: block;
}

.custom-table td, .custom-table th {
  padding: 5px 10px;
  text-transform: unset;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image, 
.image-gallery-thumbnail, 
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails, 
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, 
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  width: 50px;
}

.diamond-detail-grid-row {
  padding: 8px 10px;
  border-top: 0.5px solid gainsboro;
}

.diamond-detail-grid-row img.diamond-shape-img {
  max-width: 25px;
}

.diamond-media-thumbnail img {
  width: 55px;
  height: 55px;
  object-fit: cover;
}

.diamond-media-thumbnail button {
  width: 55px;
  height: 55px;
  background-color: gainsboro;
}

.diamond-media-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1 / 1; /* Ensures the container is a square */
}

.hold-diamond-table img, .table-diamond-img {
  max-width: 50px;
}

.hold-diamond-table th {
  padding: 10px 15px;
}

.hold-diamond-table td {
  padding: 5px 15px;
}

.diamond-media-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keeps the image aspect ratio intact */
}

.diamond-media-thumbnail, .diamond-media-img {
  cursor: pointer;
}

span.sibebar-menu-category {
  cursor: initial;
  color: #9b9b9b;
  font-size: 15px;
  margin-left: 10px;
}

.diamond-type-badge {
  display: flex !important;;
  height: 15px;
  width: 15px;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
}

.col-align-right > div {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.col-align-center  > div {
  display: flex;
  text-align: center;
  justify-content: center;
}

.sa-detail-container {
  width: 100%;
  padding: 20px;
}

.sa-profile-img {
  width: 70px;
  height: 70px;
  background-color: gainsboro;
  border-radius: 50px;
  margin-right: 20px;
}

span.sibebar-menu-category {
  cursor: initial;
  color: #9b9b9b;
  font-size: 15px;
  margin: 5px 0px;
  margin-left: 35px;
  width: 100%;
}

.sidebar-container {
  width: 70px;
  z-index: 9999;
}

.sidebar-inner-container {
  padding: 15px 0;
}

a.sidebar-menu-link {
  padding: 7px 0px;
  display: block;
  width: 100%;
}

span.sibebar-menu-category, a.sidebar-menu-link p, .sidebar-container h2.chakra-heading {
  display: none;
}

.sidebar-container:hover {
  width: auto;
}

.sidebar-container:hover span.sibebar-menu-category, 
.sidebar-container:hover a.sidebar-menu-link p,
.sidebar-container:hover h2.chakra-heading {
  display: block;
}

.sidebar-container:hover .sibebar-menu-category-dots, .sidebar-container:hover .sidebar-right-icon  {
  display: none;
}

.sidebar-container:hover .sidebar-menu-link-text {
  padding-left: 15px;
  padding-right: 30px;
}

.sidebar-container:hover a.sidebar-menu-link p {
  margin-left: 10px;
}

.chakra-ui-dark img.sidebar-logo {
  filter: invert(1);
}

img.sidebar-logo {
  height: 75px;
  max-width: 100px;
}

img.signin-page-logo {
  width: 100%;
  max-width: 75px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: -55px;
}

.sidebar-container:hover img.sidebar-logo {
  height: 120px;
}

p.chakra-menu__group__title {
  margin: 0px 12px;
  text-align: left;
  font-size: 11px;
  color: gray;
}

.chakra-menu__menuitem {
  font-size: 13px;
}

.sort-by-menu-container > div {
  max-height: 410px;
  overflow-y: scroll;
  min-width: unset !important;
  width: 180px;
}

.chakra-menu__menu-list {
  min-width: unset !important;
}

.sort-by-menu-container hr.chakra-menu__divider {
  margin-top: 0px;
}

.sort-by-menu-container hr.chakra-menu__divider:last-child {
  display: none;
}

.sidebar-logo-container {
  height: 135px;
}

/* For screens smaller than 1200px */
@media (max-width: 1199px) {
  /* CSS styles for lg */
  .sidebar-container {
    width: auto;
  }

  .sidebar-container img.sidebar-logo {
    height: 120px;
  }
  
  .sidebar-container span.sibebar-menu-category, 
  .sidebar-container a.sidebar-menu-link p,
  .sidebar-container h2.chakra-heading {
    display: block;
  }
  
  .sidebar-container .sibebar-menu-category-dots, .sidebar-container .sidebar-right-icon {
    display: none;
  }
  
  .sidebar-container .sidebar-menu-link-text {
    padding-left: 15px;
    padding-right: 30px;
  }
  
  .sidebar-container a.sidebar-menu-link p {
    margin-left: 10px;
  }
}